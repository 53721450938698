import { mobileCheck } from "Utils/ReusableFunctions";
import { Component } from "react";
import HomePageContent from "./HomePageContent";
import HomePageMobile from "./HomePageMobile";
import "./_homePage.scss";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: mobileCheck(),
    };
  }

  render() {
    if (this.state.isMobile) {
      return (
        <div className="home-page">
          <HomePageMobile />
        </div>
      );
    }
    return (
      <div className="home-page">
        <HomePageContent />
      </div>
    );
  }
}

export default HomePage;

import { Component } from "react";
import HomePageContent from "./HomePageContent";

class HomePageMobile extends Component {
  render() {
    return (
      <div className="home-page-mobile">
        <HomePageContent isMobile />
      </div>
    );
  }
}

export default HomePageMobile;

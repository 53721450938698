import { HomePage } from "Pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./_app.scss";
import "./_colorVariable.scss";
import { BrowserRouter, Switch } from "react-router-dom";
import DemoVideoPage from "Pages/DemoVideo/DemoVideoPage";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const isPageViewed = sessionStorage.getItem("isPageViewed");
    if (isPageViewed !== "true") {
      sessionStorage.setItem("isPageViewed", true);

      setTimeout(() => {
        window.location.reload(true);
      }, 300);
    }
  }, []);
  return (
    <div className="loch-app">
      <BrowserRouter>
        <Switch>
          <HomePage path="/" exact />
          <DemoVideoPage path="/modulus-demo" exact />
        </Switch>
      </BrowserRouter>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        closeButton={false}
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;

import { mobileCheck } from "Utils/ReusableFunctions";
import { Component } from "react";
import DemoVideoPageContent from "./DemoVideoPageContent";
import DemoVideoPageMobile from "./DemoVideoPageMobile";
import "./_demoVideoPage.scss";

class DemoVideoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: mobileCheck(),
    };
  }

  render() {
    if (this.state.isMobile) {
      return (
        <div className="demo-video-page">
          <DemoVideoPageMobile />
        </div>
      );
    }
    return (
      <div className="demo-video-page">
        <DemoVideoPageContent />
      </div>
    );
  }
}

export default DemoVideoPage;

import { Component } from "react";
import DemoVideoPageContent from "./DemoVideoPageContent";

class DemoVideoPageMobile extends Component {
  render() {
    return (
      <div className="demo-video-page-mobile">
        <DemoVideoPageContent isMobile />
      </div>
    );
  }
}

export default DemoVideoPageMobile;

// import { Card } from "@/components/ui/card";
import { ModulusDemoVideoThumbnail } from "Images";
import { useEffect } from "react";

const DemoVideoPageContent = () => {
  useEffect(() => {
    const myVideo = document.getElementById("modulus-demo-video");
    myVideo.playbackRate = 1.5;
  }, []);
  return (
    <div className="demo-video-page-content">
      <video
        className="demo-video-page-content-video"
        id="modulus-demo-video"
        controls
        poster={ModulusDemoVideoThumbnail}
      >
        <source
          src="https://media.loch.one/Loch%20Modulus%20Demo.mp4"
          type="video/mp4"
        />
        {/* <source src="https://media.loch.one/ModulusDemo.mp4" type="video/mp4" /> */}
        {/* <source
          src="https://media.loch.one/Modulus%20Demo.MP4"
          type="video/mp4"
        /> */}
      </video>
    </div>
  );
};

export default DemoVideoPageContent;
